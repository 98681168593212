<template>
    <div class="joinin">
        <myhead :param="param" />
        <section>
            <div class="bannerjoinin">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;">合作伙伴与加盟</div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable" >
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">合作</span>伙伴</div>
                </div>
                <div class="hcell htable" style="width: 1400px; flex-wrap:wrap;justify-content: space-between;">
                    <div class="imgdiv"  v-for="(n,i) of rowshb.length" :key="'item'+i">
                        <img class="mimg" v-if="rowshb[i].length>0" :src="rowshb[i]" ></div>
                </div>
                <div class="hcell" style="width: 130px;text-align: left;"></div>
            </div>
        </section>
        <section class="joinbj" style="margin-top: 50px;">
                <div class="body1660 htable" style="height:100%;color: #ffffff;">
                    <div class="hcell" style="width: 130px;">
                        <div  style="margin-top: 90px;"><span class="btLine">加盟</span>辉腾
                        </div>
                    </div>
                    <div class="hcell" style="width: 1400px;" >
                        <div style="margin-top: 90px;font-size: 36px;font-weight: bold;color: rgba(255,255,255,0.9)">成为辉腾合作伙伴</div>
                        <div style="margin-top: 66px;font-size: 18px;line-height: 32px; color: rgba(255,255,255,0.8)">
                            根据中国文化和旅游部统计数据显示，2010-2019年我国旅游业总规模实现稳步增长，旅游产业正在成为经济增长的重要引擎;<br/>
                            2019年国内游客60.1亿人次，首次突破60亿次，国内旅游收入已达57251亿元。<br/>
                            除了长假旅游以外，近几年，越来越多的年轻父母更愿意在周末陪同自己的孩子在户外享受亲子互动，周末短期出游与亲子互动烹饪，<br/>
                            让孩子在户外也能享受健康美食成为当下主流。<br/>
                            辉腾电宝宝目前是市面上与气罐火炉、酒精炉等炉具相比最安全和实用的能源设备，不担心孩子烧伤、不惧户外风力，电力不足时，<br/>
                            还可在户外太阳能直接充电，正是外出游玩必备神器之一。
                        </div>
                    </div>
                    <div class="hcell" style="width: 130px;" ></div>
                </div>
        </section>


        <section style="margin-top: 70px;">
            <div class="body1400">
                <div style="font-size: 36px;font-weight: bold;margin-bottom: 50px">加盟优势</div>
                <div v-for="(n,i) of Math.ceil((rowsAdvan.length)/2)  " :key="'item'+rowsAdvan[2*i].tb_id">
                    <div class="htable" style="width: 100%;margin-bottom: 40px;" >
                        <div class="hcell" style="width: 600px;">
                            <img :src="rowsAdvan[2*i].tb_image"  style="display: flex">
                        </div>
                        <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                            <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:100px;">
                                <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                    {{rowsAdvan[2*i].tb_title}}
                                </div>
                                <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                    {{rowsAdvan[2*i].tb_summary}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="htable" style="width: 100%;margin-bottom: 40px;" v-if="rowsAdvan[2*i+1]">
                        <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                            <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:80px;">
                                <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                    {{rowsAdvan[2*i+1].tb_title}}
                                </div>
                                <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                    {{rowsAdvan[2*i+1].tb_summary}}
                                </div>
                            </div>
                        </div>
                        <div class="hcell" style="width: 600px;">
                            <img :src="rowsAdvan[2*i+1].tb_image"  style="display: flex">
                        </div>
                    </div>
                </div>
                <!--
                    <div class="htable" style="margin-bottom: 40px;">
                        <div class="hcell" style="width: 600px;">
                            <img :src="ysp1"  style="display: flex">
                        </div>
                        <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                            <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:100px;">
                                <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                    专注弱光高效光伏研发9年
                                </div>
                                <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                    依托具有自主知识产权的核心技术，加工制造控制器、小功率逆变器等关键控制部件，采取轻资产运作方式，委外定点加工为太阳能路灯、光伏发电配套的智慧灯杆、大功率超导LED灯具等机电类设备及产品。
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="htable" style="margin-bottom: 40px;">
                        <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                            <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:80px;">
                                <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                    专业的研发团队
                                </div>
                                <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                    公司秉承创新是第一推进力理念，坚持经营理念、关键技术、产品研发、运营管理和商业模式持续创新升级。邀请专家、学者作专业顾问，从前沿市场、从发达城市的新思维、新理念、新模式上协助公司优化管理模式，招揽优秀人才，升级管理平台。积极拓展市场领域，加速推动移动电源、移动电磁炉等新产品落地应用。

                                </div>
                            </div>
                        </div>
                        <div class="hcell" style="width: 600px;">
                            <img :src="ysp2"  style="display: flex">
                        </div>
                    </div>

                    <div class="htable" style="margin-bottom: 40px;">
                        <div class="hcell" style="width: 600px;">
                            <img :src="ysp3"  style="display: flex">
                        </div>
                        <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                            <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:100px;">
                                <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                    全球累计15万套成功案例
                                </div>
                                <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                    截至2020年底，公司光伏发电站销量突破1385座，高效智能太阳能路灯销量突破30万套，辉腾能源在全球布局下游生态链，为客户提供开发、融资、设计、施工、运维等一站式系统集成解决方案。
                                </div>
                            </div>
                        </div>
                    </div>
                      <div class="htable" style="margin-bottom: 40px;">
                        <div  class="hcell" style="width: 800px;background-color:#ededed;display: flex;">
                            <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:80px;">
                                <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                    资质荣誉
                                </div>
                                <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                    公司现已拥有自主知识产权专利技术147项，软件著作权15项，国家及省部级资质荣誉100余项，全面通过GB/T29490知识产权管理体系、ISO9001质量管理体系、OHSAS180001职业健康安全管理体系认证、ISO14001环境管理体系；公司具备电力总承包、机电总承包、城市道路照明专项承包等资质。

                                </div>
                            </div>
                        </div>
                        <div class="hcell" style="width: 600px;">
                            <img :src="ysp4" style="display: flex">
                        </div>
                    </div>
-->
            </div>
        </section>

        <section style="margin-top: 80px;"></section>
    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    import { listTopicBases } from "../hts/api.js"
    export default {
        name: "",

        components: {
            myhead,
        },
        data() {
            return {
                param: {
                    pageindex: 4,
                },
                joinbj: require('../assets/joinbj.png'),
                rowshb:[
                require('../assets/hblog1.png'),
                require('../assets/hblog2.png'),
                require('../assets/hblog3.png'),
                require('../assets/hblog4.png'),
                require('../assets/hblog5.png'),
                require('../assets/hblog6.png'),
                    '','',
                ],

                ysp1: require('../assets/ysp1.png'),
                ysp2: require('../assets/ysp2.png'),
                ysp3: require('../assets/ysp3.png'),
                ysp4: require('../assets/ysp4.png'),
                rowsAdvan:[],
            }
        },
        mounted() {
            this.getList ()
        },
        methods: {
            getList () {
                // console.log(this.id)
                listTopicBases({type:'newWebsite:[advantage]', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.rowsAdvan=resData.data.advantage
                        //alert(res.data.rows[0].tb_content)
                        //alert(data.rows[0].tb_content)
                    })
                    .catch()
            },
        },
    }
</script>
<style lang="css" scoped>
    .joinin {
        width: 100%;
    }
    .bannerjoinin{
        background-image: url('~assets/joinin.png');
        height: 600px;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;

    }
    .imgdiv{
        justify-content: flex-start;
        /*margin-left: 60px;*/
        margin-bottom: 40px;
        width: 306px;
        height: 100px;
        overflow: hidden;
    }
    .mimg{
        width: 306px;
        height: 100px;
    }
    .joinbj{
        background-image: url('~assets/joinbj.png');
        height: 560px;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;

    }
</style>
